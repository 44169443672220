.all_reviews{

   columns: 4;    
}

.review{

    text-align: center;
    line-height: 20px;
    font-size: 25px;
    font-family: 'Alike';
    color: salmon;
    font-weight: 700;
}

.review img{
  
    /* border: 1px solid black; */
object-fit: contain;
width: 300px;
height: 300px;
}

.review_title {
    line-height: 40px;
    font-size: 25px;
    font-family: 'Alike';
    color: black;
    font-weight: 700;
}

.review p {
    color: rgb(100, 50, 45);
}

.stars img {
    margin: 50px 0px 0px 0px;
    /* border: 1px solid black; */
    object-fit: contain;
        width: 400px;
        height: 200px; 
margin-left: 550px;
}
@media (max-width: 376px){
    .all_reviews{
        columns: 1;
        margin-left: 0px;
    }
     
     .review img{
         /* border: 1px solid black; */
     object-fit: contain;
     width: 350px;
     height: 200px;

     }

     
     .review p {
         color: rgb(100, 50, 45);
     }
     
     .stars img {
         margin: 0px 0px 0px 0px;
         /* border: 1px solid black; */
         object-fit: contain;
             width: 350px;
             height: 200px; 
             margin-left: 15px;
     }
    }

    @media (min-width:377px) and (max-width: 421px){
        .all_reviews{
   
            columns: 1;
       margin-left: 0px;
             
         }
    
         
         .review img{
             /* border: 1px solid black; */
         object-fit: contain;
         width: 370px;
         height: 200px;
    
         }
         
         .review_title {
             line-height: 40px;
             font-size: 25px;
             font-family: 'Alike';
             color: black;
             font-weight: 700;
         }
         
         .review p {
             color: rgb(100, 50, 45);
         }
         
         .stars img {
             margin: 0px 0px 0px 0px;
             /* border: 1px solid black; */
             object-fit: contain;
                 width: 370px;
                 height: 200px; 
                 margin-left: 25px;
         }
    }