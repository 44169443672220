.about_andrei_title{
  
  
    height: 500px;
    width: 600px;
    display: flex;
    flex-direction: column;
text-align:left;
    margin-left: 100px;
   line-height: 40px;
}
.about_andrei_title p {
  
    font-family: Glacial;
    font-size: 20px;
    font-weight: 500;
    /* color: #b47404; */
  color: black;
  text-align: justify;
}
.wrapper{
display: flex;
flex-direction: row;
}

header{


 display: flex;
 /* transform-style: preserve-3d;
 z-index: -1; */
margin-bottom: 150px;
height: 550px;
}


.wrapper img {

  object-fit: contain;
  /* z-index: -1;
  transform: translateZ(-10px) scale(2); */
  height: 650px;
  width:650px;
  border: 1px solid black;
  border-radius: 19px;
  margin-left: 100px;
  object-fit: contain;

}

.line {
  width: 900px;
  margin-left: 280px;
  margin-bottom: 150px;
  border-bottom: 1px solid black;
  justify-content: center;
  justify-items: center;

}

.about_andrei_title_italian{
  margin-top: 100px;
  height: 400px;
  width: 550px;
  display: flex;
  flex-direction: column;
text-align:left;
  margin-left: 100px;
 line-height: 40px;

}

@media (max-width: 376px){
  .about_andrei_title{
    margin-top: 380px;
    height: 500px;
    width: 350px;
    display: flex;
    flex-direction: column;
text-align:justify;
    margin-left: 10px;
   line-height: 40px;
  
}
.about_andrei_title p {
  
    font-family: Glacial;
    font-size: 20px;
    font-weight: 500;
    /* color: #b47404; */
  color: black;
  text-align: justify;
}
.wrapper{
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 900px;
  
}

header{

 position: relative;
 display: flex;
 /* transform-style: preserve-3d;
 z-index: -1; */
margin-bottom: 150px;
height: 350px;
}


.wrapper img {
  position: absolute;
  object-fit: contain;
  /* z-index: -1;
  transform: translateZ(-10px) scale(2); */
  height: 350px;
  width:350px;
  border: 1px solid black;
  border-radius: 19px;
right: 0px;

}

.line {
  width: 350px;
  margin-left: 0px;
  margin-bottom: 150px;
  border-bottom: 1px solid black;
  justify-content: center;
  justify-items: center;

}
}

@media (min-width:377px) and (max-width: 420px){

  .about_andrei_title{
  
    margin-top: 420px;
    height: 350px;
    width: 400px;

    display: flex;
    flex-direction: column;
text-align:left;
    margin-left: -380px;
   line-height: 40px;
}
.about_andrei_title p {
  
    font-family: Glacial;
    font-size: 20px;
    font-weight: 500;
    /* color: #b47404; */
  color: black;
  text-align: justify;
}
.wrapper{
display: flex;
flex-direction: column;

width: 400px;
  height: 900px;
}

header{


 display: flex;
 /* transform-style: preserve-3d;
 z-index: -1; */
margin-bottom: 150px;
height: 550px;

}


.wrapper img {
object-fit: contain;
  object-fit: contain;
  /* z-index: -1;
  transform: translateZ(-10px) scale(2); */
  height: 400px;
  width:400px;
  border: 1px solid black;
  border-radius: 19px;
  margin-left: 10px;
  right: 0px;

}

.line {
  width: 400px;
  margin-left: 0px;
  margin-top: 200px;
  margin-bottom: 50px;
  border-bottom: 1px solid black;
  justify-content: center;
  justify-items: center;

}
}
