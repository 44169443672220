.container_contacts {
    width: 100%;
   
   
  
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 100px;
    justify-items: center;
    justify-content: center;

}

.container_contacts button{
    height: 50px;
    width: 400px;
    border: 1px solid  #f8ecd8;;
    background-color:  #f8ecd8;;
    border-radius: 19px;
    box-shadow: 0 0 25px  #cd8f25;
}

.container_contacts p {
    font-size: 20px;
    font-family: Glacial;
}

@media (max-width: 376px){
    .container_contacts {
        width: 370px;
 
        justify-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-left: 0px;
    }
    
    .container_contacts button{
        height: 50px;
        width: 300px;
        border: 1px solid  #f8ecd8;;
        background-color:  #f8ecd8;;
        border-radius: 19px;
        box-shadow: 0 0 25px  #cd8f25;
      
     
    }
    
    .container_contacts p {
        font-size: 20px;
        font-family: Glacial;
    }
}

@media (min-width:377px) and (max-width: 421px){
    .container_contacts {
        width: 100%;
     margin-left: 0px;
        justify-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    
    .container_contacts button{
        height: 50px;
        width: 300px;
        border: 1px solid  #f8ecd8;;
        background-color:  #f8ecd8;;
        border-radius: 19px;
        box-shadow: 0 0 25px  #cd8f25;
      
     
    }
    
    .container_contacts p {
        font-size: 20px;
        font-family: Glacial;
    }
}