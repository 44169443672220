
@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {transform: translateX(-100%);}
}
.pic_carousel
{
    overflow: hidden;

}
.slider{
  
    white-space: nowrap;
    animation: 20s slide infinite linear;
  
}
.slider img { 

    height: 700px;
    width:700px;
   margin-left: 30px;
    object-fit: contain;

}
.border_pic img {
    position: absolute;
 height: 120px;
 width: 1512px;
top: 801px;
}
.border_pic_up img{
    transform: rotate(180deg);
    position: absolute;
 height: 120px;
 top:396px;
 width: 1512px;
 z-index: 1;

}

@media (max-width: 376px) {
    @keyframes slide {
        from {
            transform: translateX(0);
        }
        to {transform: translateX(-50%);}
    }
    .pic_carousel
    {
        overflow: hidden;
  
    
    }
    .slider{
      
        white-space: nowrap;
        animation: 5s slide infinite linear;
      
    }
    .slider img { 

        height: 200px;
        width:200px;
       margin-left: 5px;
        object-fit: contain;
    
    }
    .border_pic img {
        position: absolute;
     height: 50px;
     width: 375px;
    top: 377px;
   
    }
    .border_pic_up img{
        transform: rotate(180deg);
        position: absolute;
     height: 50px;
     top:273px;
     width: 375px;
     z-index: 1;
    
    
    }
}

@media (min-width:377px) and (max-width: 420px){
    @keyframes slide {
        from {
            transform: translateX(0);
        }
        to {transform: translateX(-100%);}
    }
    .pic_carousel
    {
        overflow: hidden;
    
    }
    .slider{
      
        white-space: nowrap;
        animation: 5s slide infinite linear;
      
    }
    .slider img { 

        height: 200px;
        width:200px;
       margin-left: 5px;
        object-fit: contain;
    
    }
    .border_pic img {
        position: absolute;
     height: 50px;
     width: 419px;
    top: 419px;
   
    }
    .border_pic_up img{
        transform: rotate(180deg);
        position: absolute;
     height: 50px;
     top:273px;
     width: 419px;
     z-index: 1;
    
    
    }
}