.phrase_container{
 
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    font-family:  Glacial;
    font-size: 20px;
    line-height: 50px;
    font-weight: 100; 
    margin-bottom: 150px;
    border-bottom: 1px solid rgb(11, 11, 11); 
    width: 1000px;
   margin-left: 250px;
    border-top: 1px solid rgb(11, 11, 11);
    padding-top: 15px;
    padding-bottom: 15px;
}


.phrase_title_English{ 
  
display: flex;
flex-direction: column;
    color: black;
    width: 1000px;
    font-family:  Glacial;
    font-size: 25px;
    line-height: 50px;
    font-weight: 100;  
    
}

@media(max-width: 376px){
    .phrase_container{
 
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        font-family:  Glacial;
        font-size: 20px;
        line-height: 50px;
        font-weight: 100; 
        margin-bottom: 50px;
        border-bottom: 1px solid rgb(11, 11, 11); 
        width: 320px;
        margin-left: 20px;
        border-top: 1px solid rgb(11, 11, 11);
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    
    .phrase_title_English{ 
      
    display: flex;
    flex-direction: column;
        color: black;
        width: 320px;
        font-family:  Glacial;
        font-size: 25px;
        line-height: 50px;
        font-weight: 100;  
        margin-left: 0px;
    }
}

@media (min-width:377px) and (max-width: 421px){
    .phrase_container{
 
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
        font-family:  Glacial;
        font-size: 20px;
        line-height: 50px;
        font-weight: 100; 
        margin-bottom: 50px;
        border-bottom: 1px solid rgb(11, 11, 11); 
        width: 380px;
       margin-left: 20px;
        border-top: 1px solid rgb(11, 11, 11);
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    
    .phrase_title_English{ 
      
    display: flex;
    flex-direction: column;
        color: black;
        width: 380px;
        font-family:  Glacial;
        font-size: 15px;
        line-height: 50px;
        font-weight: 100;  
        
    }
}

