.about_me_block{
    height: 700px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
     margin-top: 200px;
     margin-bottom: 100px;
     
}

.about_me_pic img{
    height: 550px;
  width:550px;
    object-fit: contain;
    margin-left: 100px;
    border:1px solid black;
    border-radius: 19px;
}

.about_title {
  
   display: flex;
   flex-direction: column;
    height: 700px;
  width: 700px;
  margin-right: 100px;
 margin-bottom: 100px;
    font-family: Glacial;
    font-size: 17px;
    font-weight: 500;
   line-height: 40px;
    
    
}

@media (max-width: 376px){
  .about_me_block{
    height: 700px;
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     margin-top: 70px;
     margin-bottom: 450px;
     margin-left: 10px;
     
}

.about_me_pic img{
    height: 350px;
  width:350px;
    object-fit: contain;
    margin-left: 0px;
    border:1px solid black;
    border-radius: 19px;
}

.about_title {

   display: flex;
   flex-direction: column;
    height: 700px;
  width: 370px;
  margin-right: 100px;
 margin-bottom: 0px;
    font-family: Glacial;
    font-size: 17px;
    font-weight: 500;
   line-height: 40px;
    
    
}
}

@media (min-width:377px) and (max-width: 421px){
  .about_me_block{
    height: 700px;
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     margin-top: 70px;
     margin-bottom: 350px;
     margin-left: 5px;
     
}

.about_me_pic img{
    height: 400px;
  width:400px;
    object-fit: contain;
    margin-left: 0px;
    border:1px solid black;
    border-radius: 19px;
}

.about_title {
   display: flex;
   flex-direction: column;
    height: 700px;
  width: 400px;
  margin-right: 0px;
 margin-bottom: 0px;
    font-family: Glacial;
    font-size: 17px;
    font-weight: 500;
   line-height: 30px;
    
    
}
}