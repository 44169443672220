.contacts_page_container{
    margin-top: 300px;
    justify-content: center;
    justify-items: center;
    width: 600px;
    height: 200px;
    display: flex;
    border: 2px solid black;
    border-radius: 25px;
    margin-left: 450px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 150px;
    gap: 30px;

}

.contacts_logo img {
    height: 200px;
    width: 200px;
    

   
}

.contacts_title {
    font-size: 20px;
    font-family: Glacial;
    width: 100px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    
}

.contacts_info{
    font-size: 20px;
    font-family: Glacial;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
}

.contacts_info button{
    height: 40px;
    width: 300px;
    border: 1px solid  #f8ecd8;;
    background-color:  #f8ecd8;;
    border-radius: 19px;
    box-shadow: 0 0 15px  #cd8f25;

}

@media (max-width: 376px){
    .contacts_page_container{
      
        margin-top: 100px;
        justify-content: center;
        justify-items: center;
        width: 350px;
        height: 210px;
        display: flex;
        border: 2px solid black;
        border-radius: 25px;
        margin-left: 10px;
        padding-top: 20px;
        margin-bottom: 10px;
        gap: 20px;
    
    }
    
    .contacts_logo img {
        height: 70px;
        width: 70px;
        margin-top: 70px;
        
    
       
    }
    
    .contacts_title{
        font-size: 20px;
        font-family: Glacial;
        width: 50px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
  
    }
    
    .contacts_info{
        font-size: 20px;
        font-family: Glacial;
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       
    }
    .contacts_info button{
        height: 40px;
        width: 200px;
        border: 1px solid  #f8ecd8;;
        background-color:  #f8ecd8;;
        border-radius: 19px;
        box-shadow: 0 0 15px  #cd8f25;
    
    }
}

    @media (min-width:377px) and (max-width: 421px){
        .contacts_page_container{
      
            margin-top: 50px;
            justify-content: center;
            justify-items: center;
            width: 400px;
            height: 210px;
            display: flex;
            border: 2px solid black;
            border-radius: 25px;
            margin-left: 10px;
            padding-top: 20px;
            margin-bottom: 10px;
            gap: 20px;
        
        }
        
        .contacts_logo img {
            height: 70px;
            width: 70px;
            margin-top: 70px;
            
        
           
        }
        
        .contacts_title{
            font-size: 20px;
            font-family: Glacial;
            width: 50px;
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content:space-between;
      
        }
        
        .contacts_info{
            font-size: 20px;
            font-family: Glacial;
            width: 200px;
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
           
        } 

        .contacts_info button{
            height: 40px;
            width: 200px;
            border: 1px solid  #f8ecd8;;
            background-color:  #f8ecd8;;
            border-radius: 19px;
            box-shadow: 0 0 15px  #cd8f25;
        
        }
    }