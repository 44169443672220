.photo_arr {

 display: grid;
    margin-top: 0px;
    grid-template-columns: repeat(3, 1fr);
    height: 650px;
     object-fit: contain;
      gap:20px;
        margin-left: 30px;
     

}

@media (max-width: 376px) {
    
    .photo_arr {

        display: grid;
           margin-top: 0px;
           grid-template-columns: repeat(1, 1fr);
     
          height: 1450px;
            width: 350px;
              object-fit: contain;
              gap:20px;
              margin-left: 20px;
            
       
       }
    }

@media (min-width:377px) and (max-width: 421px){
    .photo_arr {

        display: grid;
           margin-top: 50px;
           grid-template-columns: repeat(1, 1fr);
   width: 400px;
          height: 1700px;
            
              object-fit: contain;
              gap:20px;
              margin-left: 20px;
            
       
       }
}