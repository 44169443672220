.mainPage {
margin-top: 200px;
}


  @media (max-width: 376px){
    
    .mainPage {
      margin-top: 50px;
      }
  }

  @media (min-width:377px) and (max-width: 420px){
    .mainPage {
      margin-top: 50px;
      }
      


  }