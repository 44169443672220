.serveces_header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    font-weight: 500;
    font-size: 48px;
    font-family: 'Alike';
    color: black;
}

.line{
   
    font-size: 80px;
    font-family:  "Alike";
    font-weight: 200;
    color: #b08334;
 
}


.services_icon img{
    height: 100px;
    width: 100px;
}

.services_icon{

    width: 1000px;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 gap: 150px;
 margin-top: 100px;
 margin-left: 270px;
 
}


 .serveces_title{
 
    margin-top: 100px;
    margin-left: 250px;  
 display: flex;
 flex-direction: row;

 gap: 100px;
 }

 .serveces_title p{
   
    font-size: 23px;
    font-weight: 600;
    font-family: 'Alike';
    color:#000000;
   width: 200px;
 }


@media (max-width: 376px){

    .serveces_header{
        display: flex;
      width: 360px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: 100;
        font-size: 18px;
        font-family: 'Alike';
        color: black;
        margin-top: -100px;
    }
    
    .services_icon img{
        height: 40px;
        width: 40px;
    }
    
    .services_icon{
    
        width: 10px;
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     gap: 30px;
     margin-top: 10px;
     margin-left: 30px;
     
    }
    
    
     .serveces_title{
   
        margin-top: -230px;
        margin-left: 100px;  
     display: flex;
     flex-direction: column;
      gap: 22px;
     }
    
     .serveces_title p{
       
        font-size: 14px;
        font-weight: 400;
        font-family: 'Alike';
        color:#000000;
       width: 200px;
     }

}
