
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
}

.social_icons {
    height: 30px;
    width: 400px;
    cursor: pointer;
    color: gray;
    display: flex;
    flex-direction: row;
   gap: 30px;
   justify-content: center;
    font-size: 15px;
}
.social_icons a {
    text-decoration: none;
    color: gray;
}
.copyright {
    height: 30px;
    width: 400px;
    cursor: pointer;
    flex-direction:column;
    justify-content: space-between;
    font-size: 15px;
    font-family: 'Montserrat';
         font-weight:  100;
         font-size: 10px;
         text-transform: uppercase;

}

@media(max-width: 376px){ 

 .social_icons {

    height: 25px;
    width: 200px;
    cursor: pointer;
    margin-left: 100px;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:50px;
    font-size: 15px;
   
}

.copyright {
    height: 30px;
    width: 375px;
    cursor: pointer;
    margin-left: 0px; 
    flex-direction: row;
    font-size: 9px;
    margin-top: 10px;
    display: flex;
        align-items: center;
        justify-content: center;
}  

    .footer {
        margin-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
    
    }
}