.nav_block {
  background-color: #F6EDE4;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 
    padding-top: 50px;
   cursor: pointer;
    }
    .nav_menu {
        
          display: flex;
          flex-direction: row;
          width: 500px;
        margin-top: 15px;
    justify-content: space-between;
    padding-right: 100px;
    font-family:  Glacial;
    font-weight:  600;
    font-size: 17px;
    text-transform: uppercase;
    
    
          }
    
    .nav_menu a {
        text-decoration: none;
        color: 	black;
    }
    .nav_logo img {
        position: relative;
        bottom: 75px;
        height: 200px;
        width: 200px;
      
    }

    .nav_reviews{
      height: 21px;
      width: 150px;
      /* border:1px solid black;
      border-radius: 19px; */
      justify-content: center;
      justify-items: center;
    }
    .nav_reviews button{
      width: 40px;
      margin-left: 15px;
    
    }

    .nav_reviews p {
      margin-left: 18px;
     
    }
  

    @media(max-width: 376px){
    .icon_plus{
        cursor: pointer;
        width: 60px;
        height: 60px;
        font-size: 50px;
        display: flex;
        cursor: pointer;
        margin-left: 300px;
      
        margin-top: -23px;
    }
  
    .nav_menu a {
      text-decoration: none;
      color: 	black;
     }
    
     .nav_menu.active {

                 transform: translateY(0%);
                 display: flex;
                 flex-direction: column;
           gap: 8px;
         padding-right: 0px;
           font-family: 'Alike';
           font-weight:  900;
           font-size: 15px;
           margin-top: 20px;
           margin-right: 100px;
           cursor: pointer;
         width: 100px;
         height: 50px;
       
          }
              
             .nav_logo img {
                 margin: 80px 0px 0px 0px;
                 height: 100px;
                 width: 100px;
     
               
             }
              
             .nav_block {
              
                     display: flex;
                     flex-direction: row;
                     justify-content: space-between;
                     gap: 0px;
                     margin: 0px 0px 0px 0px;
                     height: 50px;
                     padding: 0px;
                     width: 375px;
                   background-color: transparent;
                   position: sticky; }
          
           .nav_menu {
          transform: translateY(-999%);
    }
    .nav_reviews button{
      width: 80px;
      margin-left: 0px;
    }
  .nav_reviews{
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-top: 20px;
  }
}

@media (min-width:377px) and (max-width: 421px){
    
    .icon_plus{
        cursor: pointer;
        width: 60px;
        height: 60px;
        font-size: 50px;
        display: flex;
        cursor: pointer;
        margin-left: 300px;
        margin-top: -23px;
    }
    
    
     .nav_menu.active {
                 transform: translateY(0%);
                 display: flex;
                 flex-direction: column;
           gap: 8px;
         padding-right: 0px;
           font-family: 'Alike';
           font-weight:  900;
           font-size: 15px;
           margin-top: 20px;
           margin-right: 120px;
           cursor: pointer;
         width: 100px;
       
          }
              
             .nav_logo img {
                 margin: 80px 0px 0px 0px;
                 height: 100px;
                 width: 100px;
     
               
             }
              
             .nav_block {
                     display: flex;
                     flex-direction: row;
                     justify-content: space-between;
                     gap: 0px;
                     margin: 0px 0px 0px 0px;
                     height: 50px;
                     padding: 0px;
                     width: 420px;
                   background-color: transparent;
                   position: sticky;
                
                  
             }
          
           .nav_menu {
           
     transform: translateY(-999%);
    }

    .nav_reviews{
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 15px;
     }

     .nav_reviews button{
      width: 80px;
      margin-left: 0px;
    
    }
}