@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alike&display=swap');

* {
 

  margin: 0;
 padding: 0;

 
}


body {
 max-width: 1920px;
 min-height: 100vh;
 background-color: #F6EDE4;
/* display: flex; */}

@font-face {
  font-family: Glacial;
  src: url(../../../public/GlacialIndifference-Regular.otf);
}
.Glacial{
  font-family:  Glacial;
}

@media (max-width: 376px) 
 { 
    
body {
  max-width: 375px;}
}

@media (min-width:377px) and (max-width: 421px){
    
  body {
    max-width: 420px;}
  
  }