.photo_container img {
        border: 1px solid black;
        border-radius: 19px;
     width: 95%;

        margin-top: 10px;
        object-fit: contain;
       /* box-shadow:  10px 5px 5px lightgray; */
    }

    .line {
      width: 200px;
      border-top: 1px solid rgb(11, 11, 11);
      margin-bottom: 100px;
    }
 @media (max-width:376px) {
        .photo_container img {
                border: 1px solid black;
                border-radius: 19px;
                max-width: 96%;
                margin-top: 1px;
           
               /* box-shadow:  10px 5px 5px lightgray; */
            }
 }

 @media (min-width:377px) and (max-width: 421px){
   .photo_container img {
 
      border: 1px solid black;
      border-radius: 19px;
      max-width: 97%;
      margin-top: 1px;
  
  }
 }