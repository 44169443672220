 .text {
  display: flex;
   line-height: 50px;
     width: 1000px;
     height: 100px;
     align-items: center;
     text-align: center;
     font-size: 25px;
     font-family:   Glacial;
     font-weight: 300;
     margin-top: 100px;
     margin-bottom: 100px;
    padding-top: 15px;
  justify-content: center;
  justify-items: center;
  margin-left: 270px;
   }

   @media (max-width: 376px){
   
     .text {
  
    margin-left: 15px;
         width: 340px;
         height: 250px;

         align-items: center;
         text-align:justify;
         font-size: 20px;
         font-family:  Glacial;
         font-weight: 600;
         margin-bottom: 50px;
        padding-top: 0px;
       }
 }

 @media (min-width:377px) and (max-width: 421px){
    .text {
        line-height: 50px;
          width: 400px;
          height: 200px;
          align-items: center;
          text-align:center;
          font-size: 25px;
          font-family:   Glacial;
          font-weight: 300;
          margin-top: 50px;
          margin-bottom: 50px;
         padding-top: 15px;
         margin-left: 10px;
        }
     
 }